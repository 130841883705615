import directives from "./directives";
import directiveTypes from "./directiveTypes";
import materials from "./materials";
import user from "./user";
import managers from "./managers";
import roles from "./roles";
import secrets from "./secrets";
import menus from "./menus";
import login from "./login";
import projects from "./projects";
import templates from "./templates";
import helpDocuments from "./helpDocuments";
import types from "./types";
import scancodeLogin from "./scancodeLogin";
import commonTemplates from "./commonTemplates";
import publicDirective from './publicDirective';
import publicMaterial from './publicMaterial';
import apk from './apk';
import qt from './qt';
export default {
  ...commonTemplates,
  ...directives,
  ...directiveTypes,
  ...materials,
  ...user,
  ...managers,
  ...roles,
  ...secrets,
  ...menus,
  ...login,
  ...projects,
  ...templates,
  ...helpDocuments,
  ...types,
  ...scancodeLogin,
  publicDirective,
  publicMaterial,
  apk,
  qt
};
