import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/instructionType`
  : "/api/cloud/instructionType";

function queryDirectiveTypesList(data) {
  return ajax(`${href}/list`, {
    method: "POST",
    data,
  });
}

function addOrUpdateDirectiveType(data) {
  return ajax(`${href}/addOrUpdate`, {
    method: "POST",
    data,
  });
}

function getDirectiveTypeInfo(data) {
  return ajax(`${href}/findById`, {
    method: "POST",
    data,
  });
}

function deleteDirectiveTypes(data) {
  return ajax(`${href}/deleteByIds`, {
    method: "POST",
    data,
  });
}

export default {
  queryDirectiveTypesList,
  addOrUpdateDirectiveType,
  deleteDirectiveTypes,
  getDirectiveTypeInfo,
};
