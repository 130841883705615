import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/helpConfig`
  : "/api/cloud/helpConfig";

function queryHelpDocumentsList(data) {
  return ajax(`${href}/list`, {
    method: "POST",
    data,
  });
}

function getHelpDocumentInfo(data) {
  return ajax(`${href}/findById`, {
    method: "POST",
    data,
  });
}

function addOrUpdateDocument(data) {
  return ajax(`${href}/addOrUpdate`, {
    method: "POST",
    data,
  });
}
function deleteDocuments(data) {
  return ajax(`${href}/deleteByIds`, {
    method: "POST",
    data,
  });
}

export default {
  queryHelpDocumentsList,
  addOrUpdateDocument,
  deleteDocuments,
  getHelpDocumentInfo,
};
