import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/secret`
  : "/api/cloud/secret";

function querySecretKeysList(data) {
  return ajax(`${href}/querySecretList`, {
    method: "POST",
    data,
  });
}

function createSecretKey(data) {
  return ajax(`${href}/save`, {
    method: "POST",
    data,
  });
}

function bindSecretKey(data) {
  return ajax(`${href}/distributionSecret`, {
    method: "POST",
    data,
  });
}

function updateSecretKeyStatus(data) {
  return ajax(`${href}/updateStatus`, {
    method: "POST",
    data,
  });
}

function updateEndingTime(data) {
  return ajax(`${href}/updateEndingTime`, {
    method: "POST",
    data,
  });
}

export default { querySecretKeysList, createSecretKey, bindSecretKey, updateSecretKeyStatus, updateEndingTime };
