import Vue from "vue";

const components = [
  "Picture",
  "VText",
  "VButton",
  "Group",
  "EcologicalGroup",
  "RectShape",
  "StreamMedia",
  "Video",
  "Webpage",
  "Slider",
  "Weather",
  "Carousel",
  "Date",
  "MatrixIn",
  "MatrixOut",
  "MatrixExecute",
  "Environment",
  "Document",
  "StatusLight",
  "LineComp"
];

components.forEach((key) => {
  Vue.component(key, () => {
    if (key.indexOf("Matrix") > -1) {
      return import(`@/custom-component/MatrixIn`);
    } else {
      return import(`@/custom-component/${key}`);
    }
  });
});
