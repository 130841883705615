import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const baseUrl = bool
? `https://${window.location.host}/api/cloud`
: "/api/cloud";
const href = bool
  ? `https://${window.location.host}/api/cloud/project`
  : "/api/cloud/project";

const href1 = bool
  ? `https://${window.location.host}/api/cloud/projectType`
  : "/api/cloud/projectType";

const href2 = bool
  ? `https://${window.location.host}/api/cloud/projectPublic`
  : "/api/cloud/projectPublic";

function getProjectsList(data) {
  return ajax(`${href}/list`, {
    method: "POST",
    data,
  });
}

function appOrUpdateProject(data) {
  return ajax(`${href}/addOrUpdate`, {
    method: "POST",
    data,
  });
}

function getEcologyList() {
  return ajax(`${baseUrl}/ecology/list`, {
    method: "POST"
  });
}

function getListType(data) {
  return ajax(`${baseUrl}/custom/listByType`, {
    method: "POST",
    data
  });
}

function getProjectInfo(data) {
  return ajax(`${data.isCommonTemplate ? href2 : href}/findByProjectId`, {
    method: "POST",
    data: {
      id: data.id,
    },
  });
}
function deleteProjects(data) {
  return ajax(`${href}/deleteByIds`, {
    method: "POST",
    data,
  });
}

function getProjectLevelsList(data) {
  return ajax(`${href1}/list`, {
    method: "POST",
    data,
  });
}
function updateProjectImg(data) {
  return ajax(`${href}/updateImg`, {
    method: "POST",
    data,
  });
}

function copyProjects(data) {
  return ajax(`${href}/copy`, {
    method: "POST",
    data,
  });
}

function exportByProjectId(data) {
  return ajax(`${href}/exportByProjectId`, {
    method: "POST",
    data,
  });
}

function downloadProject(data) {
  return ajax(`${href}/download`, {
    method: "POST",
    data,
  });
}

export default {
  getProjectsList,
  getProjectLevelsList,
  appOrUpdateProject,
  deleteProjects,
  getProjectInfo,
  updateProjectImg,
  copyProjects,
  exportByProjectId,
  downloadProject,
  getEcologyList,
  getListType
};
