import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/file`
  : "/api/cloud/file";

function queryMaterialsList(data) {
  return ajax(`${href}/list`, {
    method: "POST",
    data,
  });
}

function uploadMaterial(data) {
  return ajax(`${href}/uploadFile`, {
    method: "POST",
    data,
  });
}
function deleteMaterial(data) {
  return ajax(`${href}/delete`, {
    method: "POST",
    data,
  });
}

export default { queryMaterialsList, uploadMaterial, deleteMaterial };
