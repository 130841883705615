import API from "@/api";

export default {
    state: {
        apkList: [],
        apkListTotal: 0
    },
    getters: {
        apkList(state) {
            return state.apkList;
        },
        apkListTotal(state) {
            return state.apkListTotal;
        }
    },
    mutations: {
        SET_APK_LIST_DATAS(state, datas) {
            state.apkList = datas || [];
        },
        SET_APK_LIST_TOTAL(state, total) {
            state.apkListTotal = total || 0;
        }
    },
    actions: {
        async getApkList({commit}, params) {
            const ret = await API.apk.queryList(params);
            commit('SET_APK_LIST_DATAS', ret && ret.data && ret.data.result);
            commit('SET_APK_LIST_TOTAL', ret && ret.data && ret.data.total);
        },
        async saveApk({}, params) {
            return await API.apk.saveData(params);
        },
        async deleteApkData({}, params) {
            return await API.apk.deleteDatas(params);
        },
        async findApkDataById({}, params) {
            return await API.apk.findDataById(params);
        },
        async updateApkData({}, params) {
            return await API.apk.updateData(params);
        },
        async updateApkStatus({}, params) {
            return await API.apk.updateStatus(params);
        }
    }
};