export default {
    state: {
        showLoading: false,
        loadingCount: 0
    },
    getters: {
        getShowLoadingState(state) {
            return state.showLoading;
        }
    },
    mutations: {
        CHANGE_LOADING_STATE(state, showLoading) {
            if (showLoading) {
                state.loadingCount++;
                state.showLoading = showLoading;
                return;
            }
            if (state.loadingCount === 0) {
                state.showLoading = false;
                return;
            }
            state.loadingCount--;
            state.showLoading = !!state.loadingCount;
        }
    },
    actions: {
        changeLoadingState({commit}, loadingState) {
            commit('CHANGE_LOADING_STATE', loadingState);
        }
    }
};