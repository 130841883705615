export const menusIconConfig = {
  ["/homePage"]: "wodeshouye",
  ["/uiDesign"]: "el-icon-monitor",
  ["/directives"]: "shenshui-zhilingguanli",
  ["/directiveTypes"]: "shenshui-zhilingguanli",
  ["/privateResource"]: "private",
  ["/materials"]: "sucaiguanli",
  ["/projects"]: "gongcheng",
  ["/helps"]: "bangzhuzhongxin",
  ["/userManage"]: "yonghuguanli",
  ["/secretKeys"]: "miyueguanli",
  ["/roles"]: "jiaoseguanli",
  ["/menus"]: "caidanguanli",
  ["/helpDocuments"]: "jurassic_mge-gcwd",
  ["/templates"]: "moban",
  ["/publicResource"]: "sources",
  ["/apk"]: "apk",
  ["/qt"]: "qt"
};
