import Vue from "vue";
import ElementUI from "element-ui";
import App from "./App";
import store from "./store";
import router from "./router";
import request from "./api";
import "@/custom-component"; // 注册自定义组件
import "@/assets/iconfont.css";
import "@/styles/animate.scss";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/reset.css";

function getWidth() {
  var c = document.documentElement.getBoundingClientRect().width * (100 / 750) ;
  document.documentElement.style.fontSize = c + "px";
}
getWidth();
window.onresize = function () { getWidth() };

Vue.use(ElementUI, { size: "small" });
Vue.config.productionTip = false;
Vue.prototype.$request = request;

Vue.prototype.$changeLoadingState = function (status) {
  this.$store.dispatch('changeLoadingState', status);
};

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
