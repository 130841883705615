<script>
import pointImg from '@/assets/point.png';
import { setCookie, getCookie } from '../../utils/utils';
export default {
  data() {
    return {
      dialogVisible: false,
      kfImg: require("../../assets/codePic.png"),
      pointImg,
      isFirst: false,
      firstGuideVisible: false,
      isOpen: true
    };
  },
  mounted() {
    // let data = sessionStorage.getItem("loginAccountInfo");
    let data = getCookie("loginAccountInfo");
    data = JSON.parse(data);
    if (data.first) {
      this.isFirst = true;
      this.isOpen = true;
      this.firstGuideVisible = true;
    }
  },
  methods: {
    commandClick(key) {
      switch (key) {
        case "new":
          const url = sessionStorage.getItem("newGuideUrl");
          window.open(url, "_blank");
          break;
        case "kf":
          this.dialogVisible = true;
          break;
      }
      this.isOpen = false;
    },
    guideClick() {
      // 无需再显示
      // let data = sessionStorage.getItem("loginAccountInfo");
      let data = getCookie("loginAccountInfo");
      data = JSON.parse(data);
      data.first = false;
      this.isFirst = false;
      // sessionStorage.setItem("loginAccountInfo", JSON.stringify(data));
      setCookie("loginAccountInfo", JSON.stringify(data), 7 * 24 *60 *60 *1000);
      const url = sessionStorage.getItem("newGuideUrl");
      window.open(url, "_blank");
      this.isOpen = false;
    },
    notNeedClick() {
      this.firstGuideVisible = false;
      // let data = sessionStorage.getItem("loginAccountInfo");
      let data = getCookie("loginAccountInfo");
      data = JSON.parse(data);
      data.first = false;
      this.isFirst = false;
      // sessionStorage.setItem("loginAccountInfo", JSON.stringify(data));
      setCookie("loginAccountInfo", JSON.stringify(data), 7 * 24 *60 *60 *1000);
      this.isOpen = false;
    },
    iconClick() {
      this.isOpen = !this.isOpen;
    }
  },
};
</script>
<template>
  <div>
    <div class="app-guide-container">
      <!-- <el-dropdown ref="guideDrop" trigger="click" placement="top-end" @command="commandClick">
        <span class="app-guide-icon"> ? </span>
        <el-dropdown-menu slot="dropdown" class="app-guide-drop">
          <el-dropdown-item icon="el-icon-question" command="new"
            >新手引导</el-dropdown-item
          >
          <el-dropdown-item icon="el-icon-chat-line-square" command="kf"
            >在线客服</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown> -->
      <div class="app-guide-content">
        <span class="app-guide-icon" @click="iconClick"> {{ isOpen ? 'X' : '?' }} </span>
        <ul class="app-guide-ul" :style="{display: isOpen ? 'block' : 'none'}">
          <li class="app-guide-li" @click="commandClick('new')">
            <i class="el-icon-question"></i>新手引导
          </li>
          <li class="app-guide-li" @click="commandClick('kf')">
            <i class="el-icon-chat-line-square"></i>在线客服
          </li>
        </ul>
      </div>
    </div>
    <el-dialog title="运营客服" width="400px" :visible.sync="dialogVisible">
      <div style="text-align:center;"><img class="image" :src="kfImg" :height="300" /></div>
    </el-dialog>
    <div v-if="isFirst">
      <div class="guide-first-mask"></div>
      <div class="guide-first" @click="guideClick">
        <i class="el-icon-question"></i>
        <span>新手引导</span>
        <div class="guide-point-continer">
          <img width="30px" :src="pointImg" />
        </div>
      </div>
    </div>
    <el-dialog
      title="新手引导"
      :visible.sync="firstGuideVisible"
      width="300px"
      class="first-guide-dialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center
    >
      <p style="text-align:center;">
        系统检测到您是首次登录！<br/>
        请点击指定位置进行基础学习<br/>
        再来使用吧！
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="firstGuideVisible = false">知道了</el-button>
        <el-button type="danger" @click="notNeedClick">无需学习</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="scss" scoped>
.app-guide-container {
  position: fixed;
  font-size: 20px;
  z-index: 5;
  right: 20px;
  bottom: 40px;
}
.app-guide-content {
  font-size: 14px;
  color: #fff;
  position: relative;
  .app-guide-ul {
    position: absolute;
    right: 0px;
    bottom: 36px;
    width: 102px;
    padding: 6px 0;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-radius: 4px;
    background: #303133;
    .app-guide-li {
      list-style: none;
      color: #fff;
      line-height: 27px;
      padding: 0 15px;
      font-size: 13px;
      cursor: pointer;
      &:hover {
        background: #484848;
      }
      i {
        margin-right: 5px;
      }
    }
  }
}
.app-guide-icon {
  background-color: #303133;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  cursor: pointer;
}
.app-guide-drop {
  background: #303133;
  border-color: #303133;
}
.guide-first-mask {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 10000;
  background: #000;
  opacity: .5;
}
.guide-first {
 position: fixed;
  z-index: 10001;
  right: 20px;
  bottom: 109px;
  width: 102px;
  height: 27px;
  background: #484848;
  cursor: pointer;
  color: #fff;
  line-height: 27px;
  padding: 0 15px;
  font-size: 13px;
  i {
    margin-right: 5px;
  }
  .guide-point-continer {
    position: absolute;
    left: 40%;
    top: 21px;
    img {
        transition: all 1s linear;
        animation-name: pointScaleAnim;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }
  }
}
.first-guide-dialog {
  z-index: 10001 !important;
}
</style>
<style lang="scss">
@keyframes pointScaleAnim {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.85);
    }

    100% {
        transform: scale(1);
    }
}
.app-guide-container {
  .el-dropdown {
    color: #fff;
  }
}
.app-guide-drop {
  .el-dropdown-menu__item {
    color: #fff;
  }
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background: #484848;
    color: #fff;
  }
  .popper__arrow {
    border-top-color: #303133 !important;
    &::after {
      border-top-color: #303133 !important;
    }
  }
}
</style>
