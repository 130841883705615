import Vue from "vue";

export default {
  mutations: {
    changeRtspAdressGroups({ curComponent }, data) {
      curComponent?.style?.rtspAdressGroups.push(data);
    },
    deleteRtspAdressGroup({ curComponent }, id) {
      curComponent.style.rtspAdressGroups =
        curComponent.style.rtspAdressGroups.filter((item) => item.id != id);
    },
    changeVideoNameGroups({ curComponent }, data) {
      curComponent?.style?.videoNameGroups.push(data);
    },
    deleteVideoGroup({ curComponent }, id) {
      curComponent.style.videoNameGroups =
        curComponent.style.videoNameGroups.filter((item) => item.id != id);
    },
    changeDocumentNameGroups({ curComponent }, data) {
      curComponent?.style?.documentNameGroups.push(data);
    },
    deleteDocumentGroup({ curComponent }, id) {
      curComponent.style.documentNameGroups =
        curComponent.style.documentNameGroups.filter((item) => item.id != id);
    },
    changeSliderListGroups({ curComponent }, data) {
      curComponent?.networkConfig?.sliderList.push(data);
    },
    deleteSliderListGroup({ curComponent }, id) {
      curComponent.networkConfig.sliderList =
        curComponent.networkConfig.sliderList.filter((item) => item.id != id);
    }
  },
};
