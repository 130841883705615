import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/dict`
  : "/api/cloud/dict";

function getTypeOptions(data) {
  return ajax(`${href}/findByCode`, {
    method: "POST",
    data,
  });
}

export default { getTypeOptions };
