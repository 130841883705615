import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";

import animation from "./animation";
import compose from "./compose";
import contextmenu from "./contextmenu";
import copy from "./copy";
import directives from "./directives";
import directiveTypes from "./directiveTypes";
import events from "./events";
import layer from "./layer";
import snapshot from "./snapshot";
import user from "./user";
import lock from "./lock";
import projectLevels from "./projectLevels";
import eventBus from "@/utils/eventBus";
import rtspAdressGroup from "./rtspAdressGroup";
import { BACK_COMPONENT } from "./../utils/attrNameData";
import publicResource from './publicResource';
import env from './env';
import apk from './apk';
import qt from './qt';
import { getCurComponentsInd, isEmpty } from '@/utils/utils';

Vue.use(Vuex);

const data = {
  state: {
    ...animation.state,
    ...compose.state,
    ...contextmenu.state,
    ...copy.state,
    ...directives.state,
    ...events.state,
    ...layer.state,
    ...snapshot.state,
    ...lock.state,
    ...directiveTypes.state,
    ...user.state,
    ...projectLevels.state,

    editMode: "edit", // 编辑器模式 edit preview
    canvasStyleData: {
      // 页面全局数据
      width: 1200,
      height: 740,
      scale: 100,
    },
    projectStyleData: {
      // 页面全局数据
      width: 1200,
      height: 740,
      scale: 100,
    },
    pageOptions: [
      {
        label: "新页面1",
        value: 1,
      },
    ],
    componentData: [BACK_COMPONENT()], // 画布组件数据
    curComponent: null,
    curComponentIndex: null,
    // 点击画布时是否点中组件，主要用于取消选中组件用。
    // 如果没点中组件，并且在画布空白处弹起鼠标，则取消当前组件的选中状态
    isClickComponent: false,
    selectedCurrentPage: 1,
    //解决粘贴复制的冒泡问题： 如果有dialog显示出来，则将该值变为true
    isEditorOnBlur: false,
    // 当前页面的错误信息
    curErrorMap: {}
  },
  actions: {
    ...directiveTypes.actions,
    ...projectLevels.actions,
  },
  mutations: {
    ...animation.mutations,
    ...compose.mutations,
    ...contextmenu.mutations,
    ...copy.mutations,
    ...directives.mutations,
    ...events.mutations,
    ...layer.mutations,
    ...snapshot.mutations,
    ...lock.mutations,
    ...directiveTypes.mutations,
    ...user.mutations,
    ...rtspAdressGroup.mutations,
    ...projectLevels.mutations,

    setClickComponentStatus(state, status) {
      state.isClickComponent = status;
    },
    switchIsEditorOnBlur(state, bol) {
      state.isEditorOnBlur = bol;
    },

    setPageOptions(state, data) {
      state.pageOptions = data;
    },

    setCurrPageName(state, data) {
      state.pageOptions.forEach((o) => {
        if (o.value == state.selectedCurrentPage) {
          o.label = data;
        }
      });
    },

    setSelectedCurrentPage(state, key) {
      state.selectedCurrentPage = key;
      const remainErrorData =
        (sessionStorage.getItem("canvasErrorMap") &&
          JSON.parse(sessionStorage.getItem("canvasErrorMap"))) || {};
      state.curErrorMap = remainErrorData[key] || {};
    },
    setCurErrorMap(state) {
      const remainErrorData =
      (sessionStorage.getItem("canvasErrorMap") &&
        JSON.parse(sessionStorage.getItem("canvasErrorMap"))) || {};
      state.curErrorMap = remainErrorData[state.selectedCurrentPage] || {};
    },
    setEditMode(state, mode) {
      state.editMode = mode;
    },

    setCanvasStyle(state, style) {
      state.canvasStyleData = style;
    },

    setProjectStyle(state, style) {
      state.projectStyleData = style;
    },

    setCurComponent(state, { component, index }) {
      state.curComponent = component;
      state.curComponentIndex = index;
    },

    setShapeStyle({ curComponent }, { top, left, width, height, rotate }) {
      if (!isEmpty(top)) curComponent.style.top = top;
      if (!isEmpty(left)) curComponent.style.left = left;
      if (width) curComponent.style.width = width;
      if (height) curComponent.style.height = height;
      if (rotate) curComponent.style.rotate = rotate;
    },

    setShapeSingleStyle({ curComponent }, { key, value }) {
      curComponent.style[key] = value;
    },

    setShapeSingleGroupStyle({ curComponent }, { key, value }) {
      curComponent.groupStyle[key] = value;
    },

    batchSetComponentAttr(state, componentMap = {}) {
      state.componentData.map((item, ind) => {
        let newVal = componentMap[item.id];
        if (newVal) {
          // 更新属性
          Object.keys(newVal).map((key) => {
            if (typeof newVal[key] === 'object' && !(newVal[key] instanceof Array)) {
              Object.keys(newVal[key]).map((objKey) => {
                if (newVal[key][objKey] !== item[key][objKey]) {
                  state.componentData[ind][key][objKey] = newVal[key][objKey];
                }
              });
            } else if (newVal[key] !== item[key]) {
              state.componentData[ind][key] = newVal[key];
            }
          })
        }
      });
    },
  
    setComponentData(state, componentData = []) {
      Vue.set(state, "componentData", componentData);
    },
    componentsAlign(state, key) {
      const { components } = state.areaData;
      // 左对齐和上对齐只需要找最小的
      let isFindMin = ['top', 'left'].includes(key);
      let alignPx = null;
      components.forEach((item,index)=>{
        let _alignPx = null;
        const { left, width, top, height } = item.style
        if(isFindMin) {
          _alignPx = item.style[key]
        } else if(key === 'right') {
          _alignPx = left + width
        } else {
          _alignPx = top + height
        }
        if(index === 0 
          || (isFindMin && _alignPx < alignPx) 
          || (!isFindMin && _alignPx > alignPx)
        ) {
          alignPx = _alignPx
        }
      })
      state.componentData = state.componentData.map((item) => {
        //背景图组件不需要对齐
        if (item.id == "Back") {
          return item;
        }
        // 只对框中元素对齐
        const inGroup = _.findIndex(components, { id: item.id }) > -1;
        if (!inGroup) {
          return item;
        }
        if(isFindMin) {
          item.style[key] = alignPx;
        } else if(key === 'right') {
          item.style.left = alignPx - item.style.width
        } else {
          item.style.top = alignPx - item.style.height
        }
        return item;
      });
      eventBus.$emit("hideArea");
    },
    horizontalEqual(state) {
      const { components } = state.areaData;
      let minLeft = 0;
      let maxRight = 0;
      let totalWidth = 0;
      let idArr = [];
      components.forEach((item,index) => {
        const { left, width } = item.style;
        const right = left + width;
        if(index === 0) {
          minLeft = left;
          maxRight = right;
        } else {
          if(left < minLeft) {
            minLeft = left
          }
          if(right > maxRight) {
            maxRight = right;
          }
        }
        totalWidth = totalWidth + width
        idArr.push({
          id: item.id,
          left,
          width
        })
      })
      idArr = idArr.sort((item,_item)=>item.left - _item.left) 
      const areaWidth = maxRight - minLeft
      const margin = (areaWidth - totalWidth) / (components.length - 1);
      let left = minLeft;
      idArr.forEach((item, index) => {
        if(index !== 0) {
          const ind = components.findIndex(h=>h.id === item.id)
          left = left + margin
          components[ind].style.left = left
        }
        left = left +  item.width
      }) 
      eventBus.$emit("hideArea");
    },
    verticalEqual(state) {
      const { components } = state.areaData;
      let minTop = 0;
      let maxBottom = 0;
      let totalHeight = 0;
      let idArr = [];
      components.forEach((item,index) => {
        const { top, height } = item.style;
        const bottom = top + height;
        if(index === 0) {
          minTop = top;
          maxBottom = bottom;
        } else {
          if(top < minTop) {
            minTop = top
          }
          if(bottom > maxBottom) {
            maxBottom = bottom;
          }
        }
        totalHeight = totalHeight + height
        idArr.push({
          id: item.id,
          top,
          height
        })
      })
      idArr = idArr.sort((item,_item)=>item.top - _item.top) 
      const areaHeight = maxBottom - minTop
      const margin = (areaHeight - totalHeight) / (components.length - 1);
      let top = minTop;
      idArr.forEach((item, index) => {
        if(index !== 0) {
          const ind = components.findIndex(h=>h.id === item.id)
          top = top + margin
          components[ind].style.top = top
        }
        top = top +  item.height
      }) 
      eventBus.$emit("hideArea");
    },
    componentsToSide(state, key) {
      const { width, height } = state.canvasStyleData;
      const { components } = state.areaData;
      state.componentData = state.componentData.map((item) => {
        //背景图组件不需要对齐
        if (item.id == "Back") {
          return item;
        }
        //当有group框的时候，则只对框中元素对齐
        //当没有group框的时候，则对所有元素对齐
        const inGroup =
          !_.isEmpty(components) &&
          _.findIndex(components, { id: item.id }) > -1;
        if (!inGroup && !_.isEmpty(components)) {
          return item;
        }
        if (key == "right") {
          item.style.left = width - item.style.width;
          return item;
        }

        if (key == "bottom") {
          item.style.top = height - item.style.height;
          return item;
        }
        item.style[key] = 0;
        return item;
      });
      eventBus.$emit("hideArea");
    },
    
    batchAddComponents(state, { components, index }) {
      if (index !== undefined) {
        state.componentData.splice(index, 0, ...components);
      } else {
        state.componentData = state.componentData.concat(components)
      }
    },

    addComponent(state, { component, index }) {
      if (index !== undefined) {
        state.componentData.splice(index, 0, component);
      } else {
        state.componentData.push(component);
      }
    },

    deleteComponent(state, index) {
      // state.componentData = state.componentData.filter(
      //   (c) => c.id != state?.curComponent?.id
      // );
      let ret = getCurComponentsInd(state.componentData, state.curComponent);
      let currentId = state.componentData[ret.index].id;
      if (ret.parentInd !== -1) {
        // 生态组件下的子组件
        state.componentData[ret.parentInd].propValue.splice(ret.index, 1);
        if (state.componentData[ret.parentInd].propValue.length === 0) {
          // 如果子按钮全部删除后，需要删除整体组件
          state.componentData.splice(ret.parentInd, 1);
        }
      } else {
        state.componentData.splice(ret.index, 1);
      }
      this.commit("setCurComponent", {
        component: state.componentData.filter((c) => c.id == "Back")[0],
      });

      // 删除当前组件的错误提示
      const data =
        (sessionStorage.getItem("canvasErrorMap") &&
          JSON.parse(sessionStorage.getItem("canvasErrorMap"))) ||
        {};
      delete data[state.selectedCurrentPage][currentId];
      sessionStorage.setItem("canvasErrorMap", JSON.stringify(data));
      this.commit("setCurErrorMap");
    },
    setComponentStyleByIndex(state, { index, top, left, width, height, rotate }) {
      if (!state.componentData || !state.componentData[index]) {
        return;
      }
      if (top) state.componentData[index].style.top = top;
      if (left) state.componentData[index].style.left = left;
      if (width) state.componentData[index].style.width = width;
      if (height) state.componentData[index].style.height = height;
      if (rotate) state.omponentData[index].style.rotate = rotate;
    },
    setEcologicalChildComponentGroupStyleByIndex(state, { index, childInd, top, left }) {
      if (top) state.componentData[index].propValue[childInd].groupStyle.top = top;
      if (left) state.componentData[index].propValue[childInd].groupStyle.left = left;
    },
    setEcologicalChildData({ curComponent }, { index, key, value }) {
      curComponent.propValue[index][key] = value;
    },
    setAreaStyle({areaData}, moveInfo) {
      const { components } = areaData;
      const horizontalMove = 'missX' in moveInfo;
      const verticalMove = 'missY' in moveInfo;
      const { missX, missY } = moveInfo
      if(components.length){
        components.forEach(item => {
          if(horizontalMove) {
            item.style.left += missX;
          }
          if(verticalMove) {
            item.style.top += missY;
          }
        })
      }
      if(horizontalMove) {
        areaData.style.left += missX;
      }
      if(verticalMove) {
        areaData.style.top += missY;
      }
    }
  },
  modules: {
    env,
    publicResource,
    apk,
    qt
  }
};

export default new Vuex.Store(data);
