import Vue from "vue";
import router from "@/router";

import { sortMenus } from "@/utils/menu";
import { getCookie } from "@/utils/utils";
export default {
  state: {
    // loginAccountInfo:
    //   (sessionStorage.getItem("loginAccountInfo") &&
    //     JSON.parse(sessionStorage.getItem("loginAccountInfo"))) ||
    //   {},
    loginAccountInfo:
      (getCookie("loginAccountInfo") &&
        JSON.parse(getCookie("loginAccountInfo"))) ||
      {},
    authorizedMenus:
      sessionStorage.getItem("routesData") &&
      JSON.parse(sessionStorage.getItem("routesData")),
  },
  mutations: {
    setLoginAccountInfo(state, data) {
      state.loginAccountInfo = data;
    },
    setAuthorizedMenus(state, { data, donotJump }) {
      const sortedData = sortMenus(data);
      state.authorizedMenus = sortedData;
      sessionStorage.setItem("routesData", JSON.stringify(sortedData));
      const path = sortedData[0].url;
      const currPath = location.href.split("#")[1];
      if (donotJump) return;
      if (currPath == path) {
        return;
      }
      path && router.push(path);
    },
  },
};
