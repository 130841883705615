import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/wechat`
  : "/api/cloud/wechat";

//验证码登录
function getQrCode(data) {
  return ajax(`${href}/get_qrcode_url`, {
    method: "GET",
    params: data,
  });
}

function checkIsCodeLogin(data) {
  return ajax(`${href}/check_login`, {
    method: "POST",
    data,
  });
}

export default {
  getQrCode,
  checkIsCodeLogin,
};
