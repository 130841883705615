import API from "@/api";

export default {
    state: {
        qtList: [],
        qtListTotal: 0
    },
    getters: {
        qtList(state) {
            return state.qtList;
        },
        qtListTotal(state) {
            return state.qtListTotal;
        }
    },
    mutations: {
        SET_QT_LIST_DATAS(state, datas) {
            state.qtList = datas || [];
        },
        SET_QT_LIST_TOTAL(state, total) {
            state.qtListTotal = total || 0;
        }
    },
    actions: {
        async getQtList({commit}, params) {
            const ret = await API.qt.queryList(params);
            commit('SET_QT_LIST_DATAS', ret && ret.data && ret.data.result);
            commit('SET_QT_LIST_TOTAL', ret && ret.data && ret.data.total);
        },
        async saveQt({}, params) {
            return await API.qt.saveData(params);
        },
        async deleteQtData({}, params) {
            return await API.qt.deleteDatas(params);
        },
        async findQtDataById({}, params) {
            return await API.qt.findDataById(params);
        },
        async updateQtData({}, params) {
            return await API.qt.updateData(params);
        },
        async updateQtStatus({}, params) {
            return await API.qt.updateStatus(params);
        }
    }
};