import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/user`
  : "/api/cloud/user";

//验证码登录
function phoneNumberWay(data) {
  return ajax(`${href}/smsLogin`, {
    method: "POST",
    data,
  });
}

function getLoginVerifyCode(data) {
  return ajax(`${href}/loginSms`, {
    method: "POST",
    data,
  });
}

export default {
  phoneNumberWay,
  getLoginVerifyCode,
};
