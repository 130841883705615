import ajax from "./request.js";
import { HOST } from './base';

function queryTableList(data) {
  return ajax(`${HOST}/filePublic/list`, {
    method: "POST",
    data
  });
}

function findPicTypeByCode(data) {
  return ajax(`${HOST}/dict/findByCode`, {
    method: "POST",
    data
  });
}


function deletePicData(data) {
  return ajax(`${HOST}/filePublic/delete`, {
    method: "POST",
    data
  });
}

function uploadPicFile(data) {
  return ajax(`${HOST}/filePublic/uploadFile`, {
    method: "POST",
    data
  });
}

export default {
  queryTableList,
  findPicTypeByCode,
  deletePicData,
  uploadPicFile
};
