import Vue from "vue";
import API from "@/api";
import { Message } from "element-ui";
export default {
  state: {
    projectLevels: [],
  },
  actions: {
    getProjectLevelsAction({ state, commit }) {
      API.getProjectLevelsList({}).then((res) => {
        if (res.status != "success") {
          Message.error(res.msg);
          return;
        }
        commit("setProjectLevels", res?.data?.result || []);
      });
    },
  },
  mutations: {
    setProjectLevels(state, data) {
      state.projectLevels = data;
    },
  },
};
