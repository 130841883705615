/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { extend } from "umi-request";
// import { notification } from "antd";
// import router from "umi/router";

import store from "@/store";
import router from "@/router";
const codeMessage = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。",
};

/**
 * 异常处理程序
 */
const errorHandler = (error) => {
  const { response } = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;

    return response;
  } else if (!response) {
    return {};
  }
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  timeout: 20000,
  errorHandler, // 默认错误处理
  // credentials: 'same-origin', //'include', // 默认请求是否带上cookie
});

// request拦截器, 改变 options.
request.interceptors.request.use((url, options) => {
  let { loginAccountInfo } = store.state || {};
  const excludeUrls = [
    "/cloud/manage_user/login",
    "/cloud/wechat/get_qrcode_url",
    "/cloud/wechat/check_login",
    "/cloud/user/loginSms",
    "/cloud/user/smsLogin",
    "/cloud/user/bind_phone",
  ];
  if (!loginAccountInfo.token && !excludeUrls.includes(url.split("/api")[1])) {
    router.push("/login");
    return Promise.reject("请登录");
  }
  if (loginAccountInfo?.token) {
    options.headers["token"] = loginAccountInfo.token;
  }
  return {
    url,
    options,
  };
});
// response拦截器, 处理response
request.interceptors.response.use(async (response) => {
  const blob = await response.clone().blob();
  if (blob.size === 0) {
    console.error("接口返回值错误，请检查接口");
    return response;
  } else if (blob.type === "application/octet-stream") {
    return response;
  } else if (blob.type === "text/html") {
    return response;
  } else {
    const data = await response.clone().json();
    let { success, status, code } = data || {};
    if (code && status == "fail") {
      if (code === "401") {
        router.push("/login");
        return {};
      }
      console.error("接口错误");
      return new Response(
        JSON.stringify({
          ...data,
          data: null,
        }),
        {
          ...response,
        }
      );
    }
    return response;
  }
});

export default request;
