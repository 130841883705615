import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/manage_user`
  : "/api/cloud/manage_user";

function queryManagers(data) {
  return ajax(`${href}/queryManageList`, {
    method: "POST",
    data,
  });
}

function resetManagerPassword(data) {
  return ajax(`${href}/reset_password`, {
    method: "POST",
    data,
  });
}

function deleteManager(data) {
  return ajax(`${href}/delete`, {
    method: "POST",
    data,
  });
}

function addOrUpdateManager(data) {
  return ajax(`${href}/${data.id ? "update" : "save"}`, {
    method: "POST",
    data,
  });
}

function getManagerInfo(data) {
  return ajax(`${href}/queryManageById`, {
    method: "GET",
    params: data,
  });
}

//管理员登录
function passwordWay(data) {
  return ajax(`${href}/login`, {
    method: "POST",
    data,
  });
}

export default {
  queryManagers,
  resetManagerPassword,
  deleteManager,
  getManagerInfo,
  addOrUpdateManager,
  passwordWay,
};
