import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/instruction`
  : "/api/cloud/instruction";

function queryDirectivesList(data) {
  return ajax(`${href}/list`, {
    method: "POST",
    data,
  });
}

function deleteDirective(data) {
  return ajax(`${href}/deleteByIds`, {
    method: "POST",
    data,
  });
}
function getDirectiveInfo(data) {
  return ajax(`${href}/findById`, {
    method: "POST",
    data,
  });
}
function addOrUpdateDirective(data) {
  return ajax(`${href}/addOrUpdate`, {
    method: "POST",
    data,
  });
}
function upgradeInstruction(data) {
  return ajax(`${href}/upgradeInstruction`, {
    method: "POST",
    data,
  });
}

export default {
  queryDirectivesList,
  deleteDirective,
  addOrUpdateDirective,
  getDirectiveInfo,
  upgradeInstruction
};
